import { HeroImage } from "@components/cards/HeroImage";
import { Carousel } from "@components/core/Carousel";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { PageModuleItem } from "@models/PageModule";
import { device } from "@styles/theme";
import { HeroWrapper, Wrapper } from "./PageModulePromotions.style";

interface Props {
	data: PageModuleItem[];
	dataTestId?: string;
}

const PageModulePromotions: React.FC<Props> = ({ data, dataTestId }) => {
	// we use device width to determine chunk size
	const isMd = useMediaQuery({ query: device.md });
	const isSm = useMediaQuery({ query: device.sm });
	const chunkSize = isSm && !isMd ? 2 : 1;
	const chunked: Array<PageModuleItem[]> = [];
	for (let i = 0; i < data.length; i += chunkSize) {
		const chunk = data.slice(i, i + chunkSize);
		chunked.push(chunk);
	}

	return (
		<Wrapper>
			<Carousel title=" " showDots showRightNav>
				{chunked.map((items, index) => (
					<HeroWrapper key={`promotions-wrapper-${index}`}>
						{items.map((item) => {
							if (
								item.image &&
								item.image.uri &&
								item.external_url &&
								item.external_url !== ""
							) {
								return (
									<HeroImage
										key={`promotions-page-module-hero-image-${item.id}`}
										image={item.image?.uri}
										link={item.external_url}
										dataTestId={dataTestId}
									/>
								);
							}
						})}
					</HeroWrapper>
				))}
			</Carousel>
		</Wrapper>
	);
};

export default PageModulePromotions;
