import {
	dataLayerReleaseImpression,
	pushProductClickEvent,
	pushProductImpressionEvent,
} from "@lib/utils/dataLayer";
import { PageModuleItem } from "@models/PageModule";
import { Release } from "@models/release";
import { useCallback, useEffect } from "react";
import { GridSlider } from "../GridSlider";

interface Props {
	title: string;
	data: PageModuleItem[];
	dataTestId?: string;
	location?: string;
}

const PageModuleReleasesGrid: React.FC<Props> = ({
	title,
	data,
	dataTestId,
	location,
}) => {
	const filteredData = data
		.filter(
			(item) => item.item && item.item_type && item.item_type.name === "release",
		)
		.map((item) => {
			return item.item as unknown as Release;
		});

	const getProductEventData = useCallback(dataLayerReleaseImpression, [title]);

	useEffect(() => {
		if (filteredData && filteredData.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredData[0].price?.code || "",
					impressions: filteredData.map((release, index) =>
						getProductEventData(release, index, title),
					),
				},
			});
		}
	}, []);

	if (data.length === 0) return <></>;

	const handleReleaseClick = (release: Release, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: release?.price?.code || "",
				click: {
					actionField: {
						list: title,
					},
					products: [getProductEventData(release, index, title)],
				},
			},
		});
	};

	return (
		<GridSlider
			location={location}
			type="releases"
			rows={2}
			title={title}
			data={filteredData || []}
			dataTestId={dataTestId}
			handleReleaseClick={handleReleaseClick}
		/>
	);
};

export default PageModuleReleasesGrid;
