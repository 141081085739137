import { HeroChartCompact } from "@components/cards/HeroChartCompact";
import { HeroImage } from "@components/cards/HeroImage";
import { Carousel } from "@components/core/Carousel";
import { HeadingH2 } from "@components/typography/Typography.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import {
	dataLayerChartImpression,
	pushProductClickEvent,
	pushProductImpressionEvent,
} from "@lib/utils/dataLayer";
import { Chart } from "@models/Chart";
import { PageModuleItem } from "@models/PageModule";
import { device } from "@styles/theme";
import { useCallback, useEffect } from "react";
import { HeroWrapper, Wrapper } from "./PageModuleDJCharts.style";

interface Props {
	data: PageModuleItem[];
	dataTestId?: string;
	title?: string;
}

const PageModuleDJCharts: React.FC<Props> = ({ data, dataTestId, title }) => {
	// we use device width to determine chunk size
	const isLg = useMediaQuery({ query: device.lg });

	const chunkSize = isLg ? 3 : 2;
	const chunked: Array<PageModuleItem[]> = [];
	for (let i = 0; i < data.length; i += chunkSize) {
		const chunk = data.slice(i, i + chunkSize);
		chunked.push(chunk);
	}

	const getChartProductEventData = useCallback(dataLayerChartImpression, [
		title,
	]);

	useEffect(() => {
		if (data && data.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: (data[0] as unknown as Chart).item?.price?.code || "",
					impressions: data
						.map((item, index) => {
							return getChartProductEventData(
								item.item as unknown as Chart,
								index,
								title,
							);
						})
						.filter((item) => item !== null),
				},
			});
		}
	}, []);

	const handleProductClick = (item: PageModuleItem, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: (data[0] as unknown as Chart).item?.price?.code || "",
				click: {
					actionField: {
						list: title || "",
					},
					products: [
						getChartProductEventData(
							item.item as unknown as Chart,
							index,
							title,
						),
					],
				},
			},
		});
	};

	return (
		<Wrapper>
			<Carousel title={<HeadingH2>DJ Charts</HeadingH2>} showDots showRightNav>
				{chunked.map((items, index) => (
					<HeroWrapper key={`hero-wrapper-${index}`}>
						{items.map((item) => {
							if (
								item.item &&
								item.item_type &&
								item.item_type.name === "chart"
							) {
								return (
									<HeroChartCompact
										key={`page-module-hero-chart${item.id}`}
										chart={item.item as unknown as Chart}
										dataTestId={dataTestId}
										handleProductClick={() => handleProductClick(item, index)}
									/>
								);
							} else if (item.image && item.external_url) {
								return (
									<HeroImage
										key={`page-module-hero-image-${item.id}`}
										image={item.image?.uri || ""}
										link={item.external_url}
										dataTestId={dataTestId}
										handleProductClick={() => handleProductClick(item, index)}
									/>
								);
							}
						})}
					</HeroWrapper>
				))}
			</Carousel>
		</Wrapper>
	);
};

export default PageModuleDJCharts;
